/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * My Emissions API
 * We calculate food carbon footprints
 * OpenAPI spec version: 1.0.0 (v1)
 */
import {
  useInfiniteQuery,
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseInfiniteQueryResult,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  PatchedProductWithRelations,
  ProductAssessResponse,
  ProductWithRelations,
  ProductsAssessCreateParams
} from '../../types'
import { apiClient } from '../../client';

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <
T,
>() => T extends Y ? 1 : 2
? A
: B;

type WritableKeys<T> = {
[P in keyof T]-?: IfEquals<
  { [Q in P]: T[P] },
  { -readonly [Q in P]: T[P] },
  P
>;
}[keyof T];

type UnionToIntersection<U> =
  (U extends any ? (k: U)=>void : never) extends ((k: infer I)=>void) ? I : never;
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never;

type Writable<T> = Pick<T, WritableKeys<T>>;
type NonReadonly<T> = [T] extends [UnionToIntersection<T>] ? {
  [P in keyof Writable<T>]: T[P] extends object
    ? NonReadonly<NonNullable<T[P]>>
    : T[P];
} : DistributeReadOnlyOverUnions<T>;



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


export const productsCreate = (
    productWithRelations: NonReadonly<ProductWithRelations>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductWithRelations>(
      {url: `/products/`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: productWithRelations
    },
      options);
    }
  


export const getProductsCreateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof productsCreate>>, TError,{data: NonReadonly<ProductWithRelations>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof productsCreate>>, TError,{data: NonReadonly<ProductWithRelations>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof productsCreate>>, {data: NonReadonly<ProductWithRelations>}> = (props) => {
          const {data} = props ?? {};

          return  productsCreate(data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ProductsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof productsCreate>>>
    export type ProductsCreateMutationBody = NonReadonly<ProductWithRelations>
    export type ProductsCreateMutationError = unknown

    export const useProductsCreate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof productsCreate>>, TError,{data: NonReadonly<ProductWithRelations>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof productsCreate>>,
        TError,
        {data: NonReadonly<ProductWithRelations>},
        TContext
      > => {

      const mutationOptions = getProductsCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    export const productsRetrieve = (
    productUuid: string,
 options?: SecondParameter<typeof apiClient>,signal?: AbortSignal
) => {
      
      
      return apiClient<ProductWithRelations>(
      {url: `/products/${productUuid}/`, method: 'GET', signal
    },
      options);
    }
  

export const getProductsRetrieveQueryKey = (productUuid: string,) => {
    return [`/products/${productUuid}/`] as const;
    }

    
export const getProductsRetrieveInfiniteQueryOptions = <TData = Awaited<ReturnType<typeof productsRetrieve>>, TError = unknown>(productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof productsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getProductsRetrieveQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof productsRetrieve>>> = ({ signal }) => productsRetrieve(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseInfiniteQueryOptions<Awaited<ReturnType<typeof productsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type ProductsRetrieveInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof productsRetrieve>>>
export type ProductsRetrieveInfiniteQueryError = unknown

export const useProductsRetrieveInfinite = <TData = Awaited<ReturnType<typeof productsRetrieve>>, TError = unknown>(
 productUuid: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof productsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getProductsRetrieveInfiniteQueryOptions(productUuid,options)

  const query = useInfiniteQuery(queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const getProductsRetrieveQueryOptions = <TData = Awaited<ReturnType<typeof productsRetrieve>>, TError = unknown>(productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof productsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}
) => {

const {query: queryOptions, request: requestOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getProductsRetrieveQueryKey(productUuid);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof productsRetrieve>>> = ({ signal }) => productsRetrieve(productUuid, requestOptions, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(productUuid),  refetchOnWindowFocus: false, retry: 1,  ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof productsRetrieve>>, TError, TData> & { queryKey: QueryKey }
}

export type ProductsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof productsRetrieve>>>
export type ProductsRetrieveQueryError = unknown

export const useProductsRetrieve = <TData = Awaited<ReturnType<typeof productsRetrieve>>, TError = unknown>(
 productUuid: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof productsRetrieve>>, TError, TData>, request?: SecondParameter<typeof apiClient>}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const queryOptions = getProductsRetrieveQueryOptions(productUuid,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



export const productsPartialUpdate = (
    productUuid: string,
    patchedProductWithRelations: NonReadonly<PatchedProductWithRelations>,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductWithRelations>(
      {url: `/products/${productUuid}/`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: patchedProductWithRelations
    },
      options);
    }
  


export const getProductsPartialUpdateMutationOptions = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof productsPartialUpdate>>, TError,{productUuid: string;data: NonReadonly<PatchedProductWithRelations>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof productsPartialUpdate>>, TError,{productUuid: string;data: NonReadonly<PatchedProductWithRelations>}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof productsPartialUpdate>>, {productUuid: string;data: NonReadonly<PatchedProductWithRelations>}> = (props) => {
          const {productUuid,data} = props ?? {};

          return  productsPartialUpdate(productUuid,data,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ProductsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof productsPartialUpdate>>>
    export type ProductsPartialUpdateMutationBody = NonReadonly<PatchedProductWithRelations>
    export type ProductsPartialUpdateMutationError = unknown

    export const useProductsPartialUpdate = <TError = unknown,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof productsPartialUpdate>>, TError,{productUuid: string;data: NonReadonly<PatchedProductWithRelations>}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof productsPartialUpdate>>,
        TError,
        {productUuid: string;data: NonReadonly<PatchedProductWithRelations>},
        TContext
      > => {

      const mutationOptions = getProductsPartialUpdateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * View which accepts a post request to assess an existing product
 */
export const productsAssessCreate = (
    productUuid: string,
    params?: ProductsAssessCreateParams,
 options?: SecondParameter<typeof apiClient>,) => {
      
      
      return apiClient<ProductAssessResponse>(
      {url: `/products/${productUuid}/assess`, method: 'POST',
        params
    },
      options);
    }
  


export const getProductsAssessCreateMutationOptions = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof productsAssessCreate>>, TError,{productUuid: string;params?: ProductsAssessCreateParams}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationOptions<Awaited<ReturnType<typeof productsAssessCreate>>, TError,{productUuid: string;params?: ProductsAssessCreateParams}, TContext> => {
const {mutation: mutationOptions, request: requestOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof productsAssessCreate>>, {productUuid: string;params?: ProductsAssessCreateParams}> = (props) => {
          const {productUuid,params} = props ?? {};

          return  productsAssessCreate(productUuid,params,requestOptions)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ProductsAssessCreateMutationResult = NonNullable<Awaited<ReturnType<typeof productsAssessCreate>>>
    
    export type ProductsAssessCreateMutationError = void

    export const useProductsAssessCreate = <TError = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof productsAssessCreate>>, TError,{productUuid: string;params?: ProductsAssessCreateParams}, TContext>, request?: SecondParameter<typeof apiClient>}
): UseMutationResult<
        Awaited<ReturnType<typeof productsAssessCreate>>,
        TError,
        {productUuid: string;params?: ProductsAssessCreateParams},
        TContext
      > => {

      const mutationOptions = getProductsAssessCreateMutationOptions(options);

      return useMutation(mutationOptions);
    }
    